<template>
  <div>
    <!-- <div v-if="this.PurityJumpList.length > 0" class="purity_build"> -->
    <div v-if="this.PurityJumpListValue">
      <div class="purity_build">
        <div class="picture">
          <img :src="baseUrl+PurityJumpList.picture" />
        </div>
        <div class="purity_context">
          <div class="purity_context_top">
            <div class="card_basic_table_contents_item">
              <p class="card_basic_table_contents_title">
                纯净度(%)：{{ PurityJumpList.purityRate }}
              </p>
              <p class="card_basic_table_contents_title">
                空置率(%)：{{ PurityJumpList.vacancyRate }}
              </p>
            </div>
            <div class="card_basic_table_contents_item">
              <p class="card_basic_table_contents_title">
                无法识别比例(%)：{{ PurityJumpList.unkownRate }}
              </p>
              <p class="card_basic_table_contents_title">
                他品比例(%)：{{ PurityJumpList.otherRate }}
              </p>
            </div>
            <div class="card_basic_table_contents_item">
              <p class="card_basic_table_contents_title">
                饱和度(%)：{{ PurityJumpList.saturationRate }}
              </p>
              <p class="card_basic_table_contents_title">
                黄金位(%)：{{ PurityJumpList.goldRate }}
              </p>
            </div>
          </div>
          <div class="purity_context_bottom">
            <el-table :data="PurityJumpList.subList" border style="width: 100%">
              <el-table-column prop="name" label="本品"></el-table-column>
              <el-table-column prop="layer" label="层数"></el-table-column>
              <el-table-column prop="skuCount" label="排面数"></el-table-column>
              <el-table-column
                prop="skuRate"
                label="排面比例(%)"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="purity_display">
        <p class="purity_display_title">陈列细明</p>
        <div class="purity_context_bottom">
          <el-table :data="PurityJumpList.infoList" border style="width: 100%">
            <el-table-column prop="name" label="SKU名称"></el-table-column>
            <el-table-column prop="layer" label="所在层数"></el-table-column>
            <el-table-column prop="skuCount" label="排面数"></el-table-column>
            <el-table-column
              prop="skuRate"
              label="排面占比(%)"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-if="!this.PurityJumpListValue" class="List_null">暂无数据</div>
  </div>
</template>
  
  <script>
import { getPurityJump } from "@/api/equipmentMonitoring.js";
import { userGetImage } from "@/api/AssetManagement";
export default {
  name: "particulars",
  data() {
    return {
      deviceRfId: "",
      PurityJumpList: [],
      baseUrl:'',
      PurityJumpListValue: false,
    };
  },
  created() {
    console.log(1);
    this.deviceRfId = this.$route.query.deviceRfId;
    this.getPurityJump();
    this.getUserGetImage()
  },
  methods: {
    getUserGetImage() {
      userGetImage().then(({ data }) => {
        if (data.resultCode === 0) {
          this.baseUrl = data.data;
        }
        console.log(data)
      });
    },
    getPurityJump() {
      this.deviceRfId = this.$route.query.deviceRfId;
      getPurityJump(this.deviceRfId).then((res) => {
        // console.log(res)
        console.log(res.data.resultCode);
        if (res.data.resultCode === 0) {
          // console.log(res.data.data)
          this.PurityJumpList = res.data.data;
          this.PurityJumpListValue = true;
          console.log("afewofijfoe");
        } else {
          console.log("afafjwe");
          this.$message(res.data.errorDesc);
          this.PurityJumpListValue = false;
        }
      });
    },
  },
};
</script>
  
  <style scoped>
.List_null {
  display: flex;
  justify-content: center;
  color: #ccc;
}
.purity_build {
  width: 100%;
  height: 405px;
  /* background-color: red; */
  display: flex;
}
.purity_build img {
  width: 100%;
  height: 100%;
}
.picture {
  width: 410px;
  height: 100%;

}
.picture img{
  transform: rotate(90deg);
}
.purity_context {
  height: 100%;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  flex-grow: 1;

}
.purity_context_top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.card_basic_table_contents_item {
  height: 130px;
  margin-left: 20px;
  /* margin-top: 20px; */
  width: 50%;
  padding-top: 82px;
}

.card_basic_table_contents_title {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #565d75;
  letter-spacing: 0;
  line-height: 28px;
  justify-content: space-between;
  /*margin-bottom: 2px;*/
}

.purity_display {
  margin-top: 30px;
}
.purity_display_title {
  font-size: 15px;
  margin-left: 5px;
  margin-bottom: 5px;
  color: #565d75;
}
</style>
  