import axios from "../utils/request";

//资产查询
export function AssetManagement(data) {
  return axios({
    url: "/clodchainAM/web/get_asset_list",
    method: "get",
    params: data
  });
}
export function exportData(data) {
  return axios({
    url: "/clodchainAM/web/batch_export_asset",
    method: "get",
    params: data,
    responseType: "blob"
  });
}

// 导出客户资产统计
export function exportCustomerAssetStatistics(data) {
  return axios({
    url: "/clodchainAM/web/export_customer_asset_statistics_info",
    method: "get",
    params: data,
    responseType: "blob"
  });
}
export function deletes(data, url, methods) {
  return axios({
    url: url,
    method: methods,
    data: data
  });
}
export function getClientList(data) {
  return axios({
    url: "/clodchainAM/web/get_customer_list",
    method: "get",
    params: data
  });
}
//PDA 添加用户
export function Adduser(data) {
  return axios({
    url: "/clodchainAM/web/add_customer_info",
    method: "post",
    data: data
  });
}

//资产信息查询
export function assetQuery(data) {
  return axios({
    url: "/clodchainAM/web/get_customer_asset_statistics_info",
    method: "get",
    params: data
  });
}
// 未入库设备编辑

export function redact(data) {
  return axios({
    url: "/clodchainAM/web/edit_asset_info",
    method: "POST",
    data: data
  });
}

// 图片管理查询

export function imgQuery(data) {
  return axios({
    url: "/clodchainAM/web/get_customer_feature_image_list",
    method: "get",
    params: data
  });
}
//拉取客户列表
export function clientName(data) {
  return axios({
    url: "/clodchainAM/web/get_customer_drop_down_list",
    method: "get",
    params: data
  });
}

// export function getDeviceType(data) {
//     return axios({
//         url:'/clodchainAM/web/device_type',
//         method:'get',
//         params:data
//     })
//
// }
//拉取资产信息客户列表
export function gitName(data) {
  return axios({
    url: "/clodchainAM/web/customer_list",
    method: "get",
    params: data
  });
}
//获取组织
export function organization(data) {
  return axios({
    url: "/clodchainAM/web/dept_name",
    method: "get",
    params: data
  });
}
//获取头像
export function userGetImage() {
  return axios({
    url: "/clodchainAM/web/user/getImage",
    method: "get",
    params: {}
  });
}
//导入
export function batchImportAsset(data) {
  return axios({
    url: "/clodchainAM/web/batch_import_asset",
    method: "post",
    ...data
  });
}
//获取组织
export function queryDeptAll(data) {
  return axios({
    url: "/clodchainAM/web/dept/queryDeptAll",
    method: "get",
    ...data
  });
}
//获取设备类型
export function getDeviceTypes(data) {
  return axios({
    url: "/clodchainAM/web/device_type",
    method: "get",
    params: data
  });
}

//获取资产型号
export function getAssetType(data) {
  return axios({
    url: "/clodchainAM/web/get_device_feature_list",
    method: "get",
    params: data
  });
}

//删除图片

export function deleteImg(data) {
  return axios({
    url: "/clodchainAM/web/delete_customer_feature_image",
    method: "post",
    data: data
  });
}

//资产管理编辑
export function assetEdit(data) {
  return axios({
    url: "/clodchainAM/web/edit_asset",
    method: "post",
    data: data
  });
}

//拉取设备年限
export function getDeviceTL(data) {
  return axios({
    url: "/clodchainAM/web/get_asset_year_list",
    method: "get",
    params: data
  });
}

//退出
export function loginout(data) {
  return axios({
    url: "/clodchainAM/web/log_out",
    method: "get",
    params: data
  });
}

//批量分配组织
export function allocation(data) {
  return axios({
    url: "/clodchainAM/web/batch_update_asset",
    method: "post",
    data: data
  });
}
//全部分配组织
export function allcontent(data) {
  return axios({
    url: "/clodchainAM/web/batch_update_asset_condition",
    method: "post",
    data: data
  });
}
// 全部控制
export function allcontrols(data) {
  return axios({
    url: "clodchainAM/web/batch_control_asset_condition",
    method: "post",
    data: data
  });
}
//勾选控制
export function allcontrol(data) {
  return axios({
    url: "clodchainAM/web/save_device_controllable_batch",
    method: "post",
    data: data
  });
}
//批量删除
export function deleteDeviceBatch(data) {
  return axios({
    url: "/clodchainAM/web/delete_device_batch",
    method: "post",
    data: data
  });
}

//编辑图片

export function editImg(data) {
  return axios({
    url: "/clodchainAM/web/add_customer_feature_image",
    method: "post",
    ...data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

// 客户配置管理-查询
export function selectCustomerList(params) {
  return axios({
    url: "/clodchainAM/web/get_customer_list",
    method: "get",
    params
  });
}
// 客户配置管理-编辑
export function saveCustomerConfig(data) {
  return axios({
    url: "/clodchainAM/web/set_customer_config",
    method: "post",
    data
  });
}
