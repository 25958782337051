import axios from "../utils/request";

export function deviceMonitorList(data) {
  return axios({
    url: "/clodchainAM/web/get_device_monitor_list",
    method: "get",
    params: data
  });
}
export function getReportInformation(data) {
  return axios({
    url: "/clodchainAM/web/get_basic_report_information",
    method: "get",
    params: data
  });
}
//温度曲线
export function getTemperatureCurve(data) {
  return axios({
    url: "/clodchainAM/web/get_device_temperature_curve",
    method: "post",
    data: data
  });
}

//获取投放信息
export function getPutInformation(data) {
  return axios({
    url: "/clodchainAM/web/get_put_report_information",
    method: "get",
    params: data
  });
}

//导出获取投放信息
export function exportPutInformation(data) {
  return axios({
    url: "/clodchainAM/web/batch_export_report_info",
    method: "get",
    params: data,
    responseType: "blob"
  });
}

//设备状态
export function deviceState(data) {
  return axios({
    url: "/clodchainAM/web/get_device_controllable_state",
    method: "get",
    params: data
  });
}

//保存
export function saves(data) {
  return axios({
    url: "/clodchainAM/web/save_device_controllable_state",
    method: "post",
    data: data
  });
}

//导出动态筛选列
export function getDynamicColumns(data) {
  console.log(data)
  return axios({
    url: "/clodchainAM/web/batch_export_report_field_list",
    method: "get",
    params:data
  });
}

export function getPurityJump(data) {
  // console.log(data) 
  return axios({
    url: "/clodchainAM/web/sku_status/getInfo",
    method: "get",
    params:{
      imei:data
    }
  });
}

